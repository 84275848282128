import React, { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import style from "./style";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import logo from "../../assets/images/menu-logo.png";
import Profile from "../Profile";
import ProfileMinified from "../ProfileMinified";
import { MenuAdmin, MenuCorrector, MenuUser } from "./menu";
import { isMobile } from "react-device-detect";
import RightIconsToolbar from "../RightIconsToolbar";
import { Paper } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles(style);

export default function Layout({ children }) {
  const userLogged = useSelector((state) => state.userLogged.id_group);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(isMobile ? false : true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar
          className={clsx(classes.wrapperToolbar, {
            [classes.wrapperToolbarEnd]: open,
            [classes.wrapperToolbarSpaceBetween]: !open,
          })}
        >
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div>
            <RightIconsToolbar />
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div className={classes.wrapperLogo}>
            <img className={classes.logo} src={logo} alt="Ligando Ideias" />
          </div>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>

        <List
          id={isMobile && open ? "sideBarOpened" : ""}
          className={classes.list}
        >
          {open && <Profile />}
          {!open && <ProfileMinified />}

          {(
            userLogged &&
            (userLogged === 1
              ? MenuAdmin
              : userLogged === 2
              ? MenuCorrector
              : MenuUser)
          )?.map((item, index) => (
            <ListItem button key={index}>
              {item.download || item.external ? (
                <a
                  rel="noreferrer"
                  target="_blank"
                  className={classes.linkMenu}
                  href={item.path}
                  download={item.download}
                >
                  <ListItemIcon className={classes.iconLink}>
                    <Icon>{item.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText
                    className={clsx(classes.textMenu, {
                      [classes.hiddenText]: !open,
                    })}
                    primary={item.title}
                  />
                </a>
              ) : (
                <Link className={classes.linkMenu} to={item.path}>
                  <ListItemIcon className={classes.iconLink}>
                    <Icon>{item.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText
                    className={clsx(classes.textMenu, {
                      [classes.hiddenText]: !open,
                    })}
                    primary={item.title}
                  />
                </Link>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>

      <main
        className={isMobile ? classes.mainContentMobile : classes.mainContent}
      >
        <Paper style={isMobile ? { padding: "0.5rem" } : { padding: "2rem" }}>
          {children}
        </Paper>
      </main>
    </div>
  );
}
