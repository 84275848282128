import React, { useCallback, useEffect, useState, forwardRef } from "react";
import { Link, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import {
  AccountCircle,
  ExitToApp,
  SignalCellularConnectedNoInternet4Bar,
  SettingsInputAntenna,
} from "@material-ui/icons";
import { Dialog, Slide, Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";
import API from "../../utils/api";
import ContentQrCodeModal from "../ContentQrCodeModal";
import { useSelector } from "react-redux";

export default function RightIconsToolbar() {
  const userLogged = useSelector((state) => state.userLogged.id_group);

  const TransitionDown = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const history = useHistory();

  const logout = useCallback(() => {
    localStorage.clear();
    history.push("/entrar");
  }, [history]);

  const [showModalQrCode, setShowModalQrCode] = useState(false);

  const verifyUserAuthentication = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        await axios.get(
          `${process.env.REACT_APP_API}/usuarios/verifica-token`,
          { headers }
        );
      } catch (error) {
        logout();
        toast.error(`Sessão expirada.`);
      }
    }
  }, [logout]);

  useEffect(() => {
    verifyUserAuthentication();
  }, [verifyUserAuthentication]);

  const getConnectionStatusNotification = async () => {
    const loadingToast = toast.loading("Verificando status Z-API...");

    try {
      const res = await API.get("/notificar-alunos/status-conexao");

      if (res.data) {
        toast.info(
          <div>
            <strong>Conectado:</strong> {res.data.connected ? "Sim" : "Não"}{" "}
            <br /> <strong>Status:</strong> {res.data.error} <br />{" "}
            <strong>Tem internet no celular?:</strong>{" "}
            {res.data.smartphoneConnected ? "Sim" : "Não"}
          </div>,
          {
            autoClose: false,
          }
        );
      }
    } catch (error) {
      toast.error(
        error?.data?.error ? `${error.data.error}` : "Ocorreu um erro."
      );
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  return (
    <>
      {userLogged && (userLogged === 1 || userLogged === 2) && (
        <IconButton onClick={getConnectionStatusNotification}>
          <Tooltip title="Status Z-API">
            <SignalCellularConnectedNoInternet4Bar />
          </Tooltip>
        </IconButton>
      )}

      {userLogged && userLogged === 1 && (
        <IconButton onClick={() => setShowModalQrCode(true)}>
          <Tooltip title="Conectar Z-API">
            <SettingsInputAntenna />
          </Tooltip>
        </IconButton>
      )}

      <Link to="/meu-perfil">
        <Tooltip title="Meu Perfil">
          <IconButton>
            <AccountCircle />
          </IconButton>
        </Tooltip>
      </Link>

      <IconButton onClick={logout}>
        <Tooltip title="Sair">
          <ExitToApp />
        </Tooltip>
      </IconButton>

      {showModalQrCode && (
        <Dialog TransitionComponent={TransitionDown} open={showModalQrCode}>
          <ContentQrCodeModal closeModal={() => setShowModalQrCode(false)} />
        </Dialog>
      )}
    </>
  );
}
