import React from "react";
import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { emailIsValid } from "../../validators/email";

export default function InputEmail({
  control,
  defaultValue,
  rules,
  errors,
  variant,
  label,
  name,
}) {
  const nameProp = name ?? "email";
  return (
    <Controller
      as={
        <TextField
          variant={variant ? variant : "outlined"}
          fullWidth
          autoComplete="off"
          error={errors[nameProp] ? true : false}
          label={label ?? "Email"}
          required={rules && rules.required ? rules.required : false}
          helperText={
            errors[nameProp] && errors[nameProp].type === "required"
              ? "Campo obrigatório"
              : errors[nameProp] && errors[nameProp].type === "validate"
              ? "Email Inválido"
              : ""
          }
          type="text"
        ></TextField>
      }
      rules={{ ...rules, validate: emailIsValid }}
      control={control}
      name={nameProp}
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
}
