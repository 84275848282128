import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import TableForm from "../../components/TableForm";
import Title from "../../components/Title";
import { Classes, FormActions } from "./style";
import InputText from "../../components/InputText";
import InputCpf from "../../components/InputCpf";
import ButtonSave from "../../components/ButtonSave";
import ButtonCancel from "../../components/ButtonCancel";
import SelectOption from "../../components/Select";
import InputPhone from "../../components/InputPhone";
import InputDate from "../../components/InputDate";
import InputEmail from "../../components/InputEmail";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../store/Loading/actions";
import API from "../../utils/api";
import moment from "moment";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
} from "@material-ui/core";
import InputCurrency from "../../components/InputCurrency";
import { formatMoney } from "../../utils/format/formatMoney";

export default function Users() {
  const dispatch = useDispatch();
  const { control, handleSubmit, errors, reset, watch } = useForm({
    mode: "all",
  });

  const [viewTable, setViewTable] = useState(true);
  const [user, setUser] = useState({});

  const [classes, setClasses] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState({});

  const watchGroup = watch("group");
  const watchLegalAdult = watch("legal_adult");

  const columns = [
    { field: "STATUS", name: "STATUS" },
    { field: "GROUP_NAME", name: "GRUPO" },
    { field: "NAME", name: "NOME" },
    { field: "PLAN", name: "PLANO" },
  ];

  const onSubmit = (data) => {
    data.cpf = data.cpf.replace(/[\s.-]*/gim, "");

    if (data.cpf_responsible) {
      data.cpf_responsible = data.cpf_responsible.replace(/[\s.-]*/gim, "");
    }

    dispatch(showLoading());
    if (!user.hasOwnProperty("ID")) {
      create(data);
    } else {
      update(data);
    }
  };

  const getAllClasses = async () => {
    try {
      const res = await API.get("/turmas/todas");
      setClasses(res.data);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  const getClassesByBroker = async (idBroker) => {
    try {
      const res = await API.get(`/turmas/corretor/${idBroker}`);

      if (res.data) {
        let obj = {};
        for (let item of res.data) {
          obj[item.ID_CLASS] = true;
        }
        setSelectedClasses(obj);
      }
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  const changeSelectedClasses = (event) => {
    setSelectedClasses({
      ...selectedClasses,
      [event.target.name]: event.target.checked,
    });
  };

  const markAllIndividualClasses = () => {
    let obj = {};
    for (let item of classes.filter((val) =>
      val.NAME.toLowerCase().includes("aula individual")
    )) {
      obj[item.ID] = true;
    }

    const oldSelectedClasses = selectedClasses;

    setSelectedClasses({ ...oldSelectedClasses, ...obj });
  };

  const markAllNightClasses = () => {
    let obj = {};
    for (let item of classes.filter(
      (val) =>
        val.NAME.toLowerCase().includes("turma noturna") ||
        val.NAME.toLowerCase().includes("niquelândia")
    )) {
      obj[item.ID] = true;
    }

    const oldSelectedClasses = selectedClasses;

    setSelectedClasses({ ...oldSelectedClasses, ...obj });
  };

  const markAllMentorships = () => {
    let obj = {};
    for (let item of classes.filter((val) =>
      val.NAME.toLowerCase().includes("mentoria")
    )) {
      obj[item.ID] = true;
    }

    const oldSelectedClasses = selectedClasses;

    setSelectedClasses({ ...oldSelectedClasses, ...obj });
  };

  const markAllHumanizedCorrections = () => {
    let obj = {};
    for (let item of classes.filter((val) =>
      val.NAME.toLowerCase().includes("correção")
    )) {
      obj[item.ID] = true;
    }

    const oldSelectedClasses = selectedClasses;

    setSelectedClasses({ ...oldSelectedClasses, ...obj });
  };

  const create = async (data) => {
    if (data.price_paid) {
      const newPricePaid = data.price_paid
        .replaceAll(",", ".")
        .replaceAll(".", "");
      data.price_paid = parseInt(newPricePaid);
    }

    if (data.group === 2) {
      data.classesBroker = Object.fromEntries(
        Object.entries(selectedClasses).filter(([key, value]) => value === true)
      );
    }

    try {
      const res = await API.post("/usuarios", data);
      setViewTable(true);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const update = async (data) => {
    if (data.price_paid) {
      const newPricePaid = data.price_paid
        .replaceAll(",", ".")
        .replaceAll(".", "");
      data.price_paid = parseInt(newPricePaid);
    }

    if (data.group === 2) {
      data.classesBroker = Object.fromEntries(
        Object.entries(selectedClasses).filter(([key, value]) => value === true)
      );
    }

    try {
      const res = await API.put(`/usuarios/${user.ID}`, data);
      setViewTable(true);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const deleteUser = async (data) => {
    try {
      const res = await API.delete(`/usuarios/${data.ID}`);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const editUser = async (user) => {
    reset();
    setSelectedClasses([]);
    user.BIRTHDAY = moment.utc(user.BIRTHDAY).format("DD/MM/YYYY");
    setUser(user);
    setViewTable(false);

    await getAllClasses();

    if (user.ID_GROUP === 2) {
      dispatch(showLoading());
      await getClassesByBroker(user.ID);
      dispatch(hideLoading());
    }
  };

  const addUser = async () => {
    await getAllClasses();
    reset();
    setSelectedClasses([]);
    setUser({
      ID_GROUP: 3,
      NAME: "",
      STATUS: 1,
      CPF: "",
      BIRTHDAY: "",
      CELLPHONE: "",
      EMAIL: "",
      INSTAGRAM: "",
      EDUCATIONAL_LEVEL: "",
      ADDRESS: "",
      SCHOOL: "",
      PLAN: "",
      PRICE_PAID: "",
      PAYMENT_DAY: "",
      LEGAL_ADULT: "",
      NAME_RESPONSIBLE: "",
      CPF_RESPONSIBLE: "",
      EMAIL_RESPONSIBLE: "",
      CELLPHONE_RESPONSIBLE: "",
    });
    setViewTable(false);
  };

  const cancelAddUser = () => {
    setViewTable(true);
  };

  return (
    <>
      <Title name="Usuários" />

      {viewTable && (
        <TableForm
          url="/usuarios"
          columns={columns}
          add={addUser}
          edit={(user) => {
            editUser(user);
          }}
          deleteItem={(user) => {
            return new Promise((resolve) => {
              deleteUser(user);
              resolve();
            });
          }}
        />
      )}

      {!viewTable && (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={4}
            direction="row"
            justifyContent="flex-start"
          >
            <Grid item xs={12} sm={4}>
              <SelectOption
                id="group"
                name="group"
                label="Grupo de usuário"
                control={control}
                defaultValue={user.ID_GROUP}
                rules={{ required: true }}
                errors={errors}
              >
                <MenuItem value={3}>Aluno(a)</MenuItem>
                <MenuItem value={2}>Professor(a)</MenuItem>
                <MenuItem value={1}>Administrador(a)</MenuItem>
              </SelectOption>
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputText
                name="name"
                label="Nome"
                control={control}
                defaultValue={user.NAME}
                rules={{ required: true }}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <SelectOption
                id="status"
                name="status"
                label="Status"
                control={control}
                defaultValue={user.STATUS}
                rules={{ required: true }}
                errors={errors}
              >
                <MenuItem value={1}>Ativado</MenuItem>
                <MenuItem value={0}>Desativado</MenuItem>
              </SelectOption>
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputCpf
                control={control}
                defaultValue={user.CPF}
                rules={{ required: true }}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputDate
                name="birthday"
                label="Data de nascimento"
                control={control}
                defaultValue={user.BIRTHDAY}
                rules={{ required: true }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputPhone
                name="cellphone"
                label="Celular"
                control={control}
                defaultValue={user.CELLPHONE}
                rules={{ required: true }}
                errors={errors}
              />
            </Grid>

            {(watchGroup === 3 || (user.ID_GROUP === 3 && !watchGroup)) && (
              <>
                <Grid item xs={12} sm={4}>
                  <InputEmail
                    name="email"
                    label="Email do aluno(a)"
                    control={control}
                    defaultValue={user.EMAIL}
                    rules={{ required: true }}
                    errors={errors}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <InputText
                    name="instagram"
                    label="Instagram"
                    control={control}
                    defaultValue={user.INSTAGRAM}
                    rules={{ required: false }}
                    errors={errors}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <SelectOption
                    id="educational_level"
                    name="educational_level"
                    label="Candidato"
                    control={control}
                    defaultValue={user.EDUCATIONAL_LEVEL}
                    rules={{ required: true }}
                    errors={errors}
                  >
                    <MenuItem value="Treineiro">Treineiro</MenuItem>
                    <MenuItem value="Participante">Participante</MenuItem>
                  </SelectOption>
                </Grid>

                <Grid item xs={12} sm={8}>
                  <InputText
                    name="address"
                    label="Endereço"
                    control={control}
                    defaultValue={user.ADDRESS}
                    rules={{ required: true }}
                    errors={errors}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <InputText
                    name="school"
                    label="Escola"
                    control={control}
                    defaultValue={user.SCHOOL}
                    rules={{ required: true }}
                    errors={errors}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <SelectOption
                    id="plan"
                    name="plan"
                    label="Plano escolhido pelo aluno(a):"
                    control={control}
                    defaultValue={user.PLAN}
                    rules={{ required: true }}
                    errors={errors}
                  >
                    <MenuItem value="AULA INDIVIDUAL AO VIVO">
                      AULA INDIVIDUAL AO VIVO
                    </MenuItem>
                    <MenuItem value="TURMA NOTURNA AO VIVO">
                      TURMA NOTURNA AO VIVO
                    </MenuItem>
                    <MenuItem value="CORREÇÃO HUMANIZADA">
                      CORREÇÃO HUMANIZADA
                    </MenuItem>
                    <MenuItem value="MENTORIA">MENTORIA</MenuItem>
                    <MenuItem value="NIQUELÂNDIA">NIQUELÂNDIA</MenuItem>
                  </SelectOption>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <InputCurrency
                    name="price_paid"
                    label="Preço cobrado do aluno"
                    control={control}
                    defaultValue={
                      user?.PRICE_PAID === "" || user?.PRICE_PAID === null
                        ? ""
                        : formatMoney(user.PRICE_PAID.toString())
                    }
                    rules={{ required: true }}
                    errors={errors}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <SelectOption
                    id="payment_day"
                    name="payment_day"
                    label="Dia do pagamento"
                    control={control}
                    defaultValue={user.PAYMENT_DAY}
                    rules={{ required: true }}
                    errors={errors}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </SelectOption>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <SelectOption
                    id="legal_adult"
                    name="legal_adult"
                    label="Maior de idade?"
                    control={control}
                    defaultValue={user.LEGAL_ADULT}
                    rules={{ required: true }}
                    errors={errors}
                  >
                    <MenuItem value={0}>Não</MenuItem>
                    <MenuItem value={1}>Sim</MenuItem>
                  </SelectOption>
                </Grid>

                {watchLegalAdult === 0 && (
                  <>
                    <Grid item xs={12} sm={4}>
                      <InputText
                        name="name_responsible"
                        label="Nome do responsável"
                        control={control}
                        defaultValue={user.NAME_RESPONSIBLE}
                        rules={{ required: true }}
                        errors={errors}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <InputCpf
                        name="cpf_responsible"
                        label="CPF do responsável"
                        control={control}
                        defaultValue={user.CPF_RESPONSIBLE}
                        rules={{ required: true }}
                        errors={errors}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <InputEmail
                        name="email_responsible"
                        label="Email do responsável"
                        control={control}
                        defaultValue={user.EMAIL_RESPONSIBLE}
                        rules={{ required: true }}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputPhone
                        name="cellphone_responsible"
                        label="Celular do responsável"
                        control={control}
                        defaultValue={user.CELLPHONE_RESPONSIBLE}
                        rules={{ required: true }}
                        errors={errors}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}

            {(watchGroup === 2 || (user.ID_GROUP === 2 && !watchGroup)) && (
              <Grid item xs={12}>
                <Classes>
                  <div>
                    <h2>Aulas individuais: </h2>

                    {classes.length > 0 &&
                      classes
                        .filter(function (val) {
                          return val.NAME.toLowerCase().includes(
                            "aula individual"
                          );
                        })
                        .map(function (item) {
                          return (
                            <FormControlLabel
                              key={item.ID}
                              control={<Checkbox name={`${item.ID}`} />}
                              checked={
                                selectedClasses[`${item.ID}`] ? true : false
                              }
                              onChange={changeSelectedClasses}
                              label={item.NAME}
                            />
                          );
                        })}

                    <br />

                    <Button onClick={markAllIndividualClasses}>
                      Marcar todas
                    </Button>

                    <h2>Turmas noturnas: </h2>

                    {classes.length > 0 &&
                      classes
                        .filter(function (val) {
                          return (
                            val.NAME.toLowerCase().includes("turma noturna") ||
                            val.NAME.toLowerCase().includes("niquelândia")
                          );
                        })
                        .map(function (item) {
                          return (
                            <FormControlLabel
                              key={item.ID}
                              control={<Checkbox name={`${item.ID}`} />}
                              checked={
                                selectedClasses[`${item.ID}`] ? true : false
                              }
                              onChange={changeSelectedClasses}
                              label={item.NAME}
                            />
                          );
                        })}

                    <br />

                    <Button onClick={markAllNightClasses}>Marcar todas</Button>

                    <h2>Mentorias: </h2>

                    {classes.length > 0 &&
                      classes
                        .filter(function (val) {
                          return val.NAME.toLowerCase().includes("mentoria");
                        })
                        .map(function (item) {
                          return (
                            <FormControlLabel
                              key={item.ID}
                              control={<Checkbox name={`${item.ID}`} />}
                              checked={
                                selectedClasses[`${item.ID}`] ? true : false
                              }
                              onChange={changeSelectedClasses}
                              label={item.NAME}
                            />
                          );
                        })}

                    <br />

                    <Button onClick={markAllMentorships}>Marcar todas</Button>

                    <h2>Correções humanizadas: </h2>

                    {classes.length > 0 &&
                      classes
                        .filter(function (val) {
                          return val.NAME.toLowerCase().includes("correção");
                        })
                        .map(function (item) {
                          return (
                            <FormControlLabel
                              key={item.ID}
                              control={<Checkbox name={`${item.ID}`} />}
                              checked={
                                selectedClasses[`${item.ID}`] ? true : false
                              }
                              onChange={changeSelectedClasses}
                              label={item.NAME}
                            />
                          );
                        })}

                    <br />

                    <Button onClick={markAllHumanizedCorrections}>
                      Marcar todas
                    </Button>
                  </div>
                </Classes>
              </Grid>
            )}
          </Grid>

          <FormActions>
            <ButtonCancel click={cancelAddUser} />
            <ButtonSave />
          </FormActions>
        </form>
      )}
    </>
  );
}
