import React from "react";
import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { cpfIsValid } from "../../validators/cpf";
import InputMask from "react-input-mask";

export default function InputCpf({
  control,
  defaultValue,
  rules,
  errors,
  variant,
  label,
  name,
}) {
  const nameProps = name ?? "cpf";
  return (
    <Controller
      as={
        <InputMask
          mask="999.999.999-99"
          value={
            control.getValues(nameProps)
              ? control.getValues(nameProps)
              : defaultValue
          }
          disabled={false}
          maskChar="_"
        >
          {() => (
            <TextField
              variant={variant ? variant : "outlined"}
              fullWidth
              autoComplete="off"
              required={rules && rules.required ? rules.required : false}
              error={errors[nameProps] ? true : false}
              label={label ?? "CPF"}
              helperText={
                errors[nameProps] && errors[nameProps].type === "required"
                  ? "Campo obrigatório"
                  : errors[nameProps] && errors[nameProps].type === "validate"
                  ? "CPF Inválido"
                  : ""
              }
              type="text"
            ></TextField>
          )}
        </InputMask>
      }
      rules={{ ...rules, validate: cpfIsValid }}
      control={control}
      name={nameProps}
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
}
