import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { WrapperBox } from "./style";

export default function GradeBox({ points, calculateTotalPoints }) {
  const userLogged = useSelector((state) => state.userLogged.id_group);

  const [pointsGrade, setPointsGrade] = useState([]);

  const changePoints = (position, value) => {
    if (userLogged !== 3) {
      const aux = [...pointsGrade];
      aux[position] = value;
      calculateTotalPoints(aux);
      setPointsGrade(aux);
    }
  };

  useEffect(() => {
    setPointsGrade(points);
  }, [points]);

  return (
    <WrapperBox>
      <div className="grade-skill skill-1">
        <div className="skill-number">
          <span>1</span>
        </div>
        <div
          onClick={() => changePoints(0, 0)}
          className={
            pointsGrade[0] === 0 ? "skill-result active" : "skill-result"
          }
        >
          <span>0</span>
          <i>0</i>
        </div>
        <div
          onClick={() => changePoints(0, 40)}
          className={
            pointsGrade[0] === 40 ? "skill-result active" : "skill-result"
          }
        >
          <span>1</span>
          <i>40</i>
        </div>
        <div
          onClick={() => changePoints(0, 80)}
          className={
            pointsGrade[0] === 80 ? "skill-result active" : "skill-result"
          }
        >
          <span>2</span>
          <i>80</i>
        </div>
        <div
          onClick={() => changePoints(0, 120)}
          className={
            pointsGrade[0] === 120 ? "skill-result active" : "skill-result"
          }
        >
          <span>3</span>
          <i>120</i>
        </div>
        <div
          onClick={() => changePoints(0, 160)}
          className={
            pointsGrade[0] === 160 ? "skill-result active" : "skill-result"
          }
        >
          <span>4</span>
          <i>160</i>
        </div>
        <div
          onClick={() => changePoints(0, 200)}
          className={
            pointsGrade[0] === 200 ? "skill-result active" : "skill-result"
          }
        >
          <span>5</span>
          <i>200</i>
        </div>
      </div>

      <div className="grade-skill skill-2">
        <div className="skill-number">
          <span>2</span>
        </div>
        <div className="skill-result">
          <span></span>
          <i></i>
        </div>
        <div
          onClick={() => changePoints(1, 40)}
          className={
            pointsGrade[1] === 40 ? "skill-result active" : "skill-result"
          }
        >
          <span>1</span>
          <i>40</i>
        </div>
        <div
          onClick={() => changePoints(1, 80)}
          className={
            pointsGrade[1] === 80 ? "skill-result active" : "skill-result"
          }
        >
          <span>2</span>
          <i>80</i>
        </div>
        <div
          onClick={() => changePoints(1, 120)}
          className={
            pointsGrade[1] === 120 ? "skill-result active" : "skill-result"
          }
        >
          <span>3</span>
          <i>120</i>
        </div>
        <div
          onClick={() => changePoints(1, 160)}
          className={
            pointsGrade[1] === 160 ? "skill-result active" : "skill-result"
          }
        >
          <span>4</span>
          <i>160</i>
        </div>
        <div
          onClick={() => changePoints(1, 200)}
          className={
            pointsGrade[1] === 200 ? "skill-result active" : "skill-result"
          }
        >
          <span>5</span>
          <i>200</i>
        </div>
      </div>

      <div className="grade-skill skill-3">
        <div className="skill-number">
          <span>3</span>
        </div>
        <div
          onClick={() => changePoints(2, 0)}
          className={
            pointsGrade[2] === 0 ? "skill-result active" : "skill-result"
          }
        >
          <span>0</span>
          <i>0</i>
        </div>
        <div
          onClick={() => changePoints(2, 40)}
          className={
            pointsGrade[2] === 40 ? "skill-result active" : "skill-result"
          }
        >
          <span>1</span>
          <i>40</i>
        </div>
        <div
          onClick={() => changePoints(2, 80)}
          className={
            pointsGrade[2] === 80 ? "skill-result active" : "skill-result"
          }
        >
          <span>2</span>
          <i>80</i>
        </div>
        <div
          onClick={() => changePoints(2, 120)}
          className={
            pointsGrade[2] === 120 ? "skill-result active" : "skill-result"
          }
        >
          <span>3</span>
          <i>120</i>
        </div>
        <div
          onClick={() => changePoints(2, 160)}
          className={
            pointsGrade[2] === 160 ? "skill-result active" : "skill-result"
          }
        >
          <span>4</span>
          <i>160</i>
        </div>
        <div
          onClick={() => changePoints(2, 200)}
          className={
            pointsGrade[2] === 200 ? "skill-result active" : "skill-result"
          }
        >
          <span>5</span>
          <i>200</i>
        </div>
      </div>

      <div className="grade-skill skill-4">
        <div className="skill-number">
          <span>4</span>
        </div>
        <div
          onClick={() => changePoints(3, 0)}
          className={
            pointsGrade[3] === 0 ? "skill-result active" : "skill-result"
          }
        >
          <span>0</span>
          <i>0</i>
        </div>
        <div
          onClick={() => changePoints(3, 40)}
          className={
            pointsGrade[3] === 40 ? "skill-result active" : "skill-result"
          }
        >
          <span>1</span>
          <i>40</i>
        </div>
        <div
          onClick={() => changePoints(3, 80)}
          className={
            pointsGrade[3] === 80 ? "skill-result active" : "skill-result"
          }
        >
          <span>2</span>
          <i>80</i>
        </div>
        <div
          onClick={() => changePoints(3, 120)}
          className={
            pointsGrade[3] === 120 ? "skill-result active" : "skill-result"
          }
        >
          <span>3</span>
          <i>120</i>
        </div>
        <div
          onClick={() => changePoints(3, 160)}
          className={
            pointsGrade[3] === 160 ? "skill-result active" : "skill-result"
          }
        >
          <span>4</span>
          <i>160</i>
        </div>
        <div
          onClick={() => changePoints(3, 200)}
          className={
            pointsGrade[3] === 200 ? "skill-result active" : "skill-result"
          }
        >
          <span>5</span>
          <i>200</i>
        </div>
      </div>

      <div className="grade-skill skill-5">
        <div className="skill-number">
          <span>5</span>
        </div>
        <div
          onClick={() => changePoints(4, 0)}
          className={
            pointsGrade[4] === 0 ? "skill-result active" : "skill-result"
          }
        >
          <span>0</span>
          <i>0</i>
        </div>
        <div
          onClick={() => changePoints(4, 40)}
          className={
            pointsGrade[4] === 40 ? "skill-result active" : "skill-result"
          }
        >
          <span>1</span>
          <i>40</i>
        </div>
        <div
          onClick={() => changePoints(4, 80)}
          className={
            pointsGrade[4] === 80 ? "skill-result active" : "skill-result"
          }
        >
          <span>2</span>
          <i>80</i>
        </div>
        <div
          onClick={() => changePoints(4, 120)}
          className={
            pointsGrade[4] === 120 ? "skill-result active" : "skill-result"
          }
        >
          <span>3</span>
          <i>120</i>
        </div>
        <div
          onClick={() => changePoints(4, 160)}
          className={
            pointsGrade[4] === 160 ? "skill-result active" : "skill-result"
          }
        >
          <span>4</span>
          <i>160</i>
        </div>
        <div
          onClick={() => changePoints(4, 200)}
          className={
            pointsGrade[4] === 200 ? "skill-result active" : "skill-result"
          }
        >
          <span>5</span>
          <i>200</i>
        </div>
      </div>
    </WrapperBox>
  );
}
