import React from "react";
import { Button, Icon } from "@material-ui/core";
import { WrapperAdd } from "./style";

export default function ButtonAdd({ click, text }) {
  return (
    <WrapperAdd>
      <Button
        className="btn-add"
        startIcon={<Icon>add_circle</Icon>}
        onClick={click ? click : () => {}}
      >
        {text ?? "ADICIONAR"}
      </Button>
    </WrapperAdd>
  );
}
