export const MenuAdmin = [
  {
    path: "/inicio",
    icon: "home",
    title: "Início",
  },
  {
    path: "/posts",
    icon: "storage",
    title: "Posts",
  },
  {
    path: "/files/folha-redacao-enem.pdf",
    icon: "print",
    title: "Folha ENEM",
    download: true,
  },
  {
    path: "/files/folha-redacao-uniube.pdf",
    icon: "print",
    title: "Folha UNIUBE",
    download: true,
  },
  {
    path: "/files/PASSO A PASSO LIGANDO IDEIAS.pdf",
    icon: "menu_book",
    title: "Tutorial",
    download: true,
  },
  {
    path: "https://meet.google.com/ris-ertz-nys",
    icon: "live_tv",
    title: "Link da aula - Lays",
    external: true,
  },
  {
    path: "https://meet.google.com/jei-ijem-gpf",
    icon: "live_tv",
    title: "Link da aula - Anabel",
    external: true,
  },
  {
    path: "/turmas",
    icon: "group",
    title: "Turmas",
  },
  {
    path: "/usuarios",
    icon: "account_box",
    title: "Usuários",
  },
  {
    path: "/intensivao",
    icon: "border_color",
    title: "Intensivão",
  },
  {
    path: "/reservas",
    icon: "sentiment_very_satisfied",
    title: "Reservas 2025",
  },
  {
    path: "/indicacao-premiada",
    icon: "recommend",
    title: "Indicação premiada",
  },
  {
    path: "/ebook",
    icon: "system_update_alt",
    title: "E-book",
  },
  {
    path: "/controle-financeiro",
    icon: "monetization_on",
    title: "Financeiro",
  },
  {
    path: "/horario",
    icon: "schedule",
    title: "Horário",
  },
  {
    path: "https://app.zapsign.com.br/conta/documentos",
    icon: "business_center",
    title: "Contratos",
    external: true,
  },
];

export const MenuCorrector = [
  {
    path: "/inicio",
    icon: "home",
    title: "Início",
  },
  {
    path: "/posts",
    icon: "storage",
    title: "Posts",
  },
  {
    path: "/files/folha-redacao-enem.pdf",
    icon: "print",
    title: "Folha ENEM",
    download: true,
  },
  {
    path: "/files/folha-redacao-uniube.pdf",
    icon: "print",
    title: "Folha UNIUBE",
    download: true,
  },
  {
    path: "/files/PASSO A PASSO LIGANDO IDEIAS.pdf",
    icon: "menu_book",
    title: "Tutorial",
    download: true,
  },
  {
    path: "https://meet.google.com/ris-ertz-nys",
    icon: "live_tv",
    title: "Link da aula - Lays",
    external: true,
  },
  {
    path: "https://meet.google.com/jei-ijem-gpf",
    icon: "live_tv",
    title: "Link da aula - Anabel",
    external: true,
  },
  {
    path: "/horario",
    icon: "schedule",
    title: "Horário",
  },
];

export const MenuUser = [
  {
    path: "/inicio",
    icon: "home",
    title: "Início",
  },
  {
    path: "/posts",
    icon: "storage",
    title: "Posts",
  },
  {
    path: "/files/folha-redacao-enem.pdf",
    icon: "print",
    title: "Folha ENEM",
    download: true,
  },
  {
    path: "/files/folha-redacao-uniube.pdf",
    icon: "print",
    title: "Folha UNIUBE",
    download: true,
  },
  {
    path: "/files/PASSO A PASSO LIGANDO IDEIAS.pdf",
    icon: "menu_book",
    title: "Tutorial",
    download: true,
  },
  {
    path: "https://meet.google.com/ris-ertz-nys",
    icon: "live_tv",
    title: "Link da aula - Lays",
    external: true,
  },
  {
    path: "https://meet.google.com/jei-ijem-gpf",
    icon: "live_tv",
    title: "Link da aula - Anabel",
    external: true,
  },
];
