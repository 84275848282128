import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Layout from "./components/Layout";

import Posts from "./pages/Posts";
import Home from "./pages/Home";
import Users from "./pages/Users";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Classes from "./pages/Classes";
import Answers from "./pages/Answers";
import MyProfile from "./pages/MyProfile";
import Intensive from "./pages/Intensive";
import Reservations from "./pages/Reservations";
import Ebook from "./pages/Ebook";
import Recommendations from "./pages/Recommendations";
import FinancialControl from "./pages/FinancialControl";
import Schedule from "./pages/Schedule";

const Routes = () => (
  <Switch>
    <Route path="/entrar" component={Login} />
    <Route path="/recuperar-senha" component={ForgotPassword} />
    <Route exact path="/">
      <Redirect to="/entrar" />
    </Route>

    <PrivateRoute path="/inicio" component={Home} />
    <PrivateRoute path="/posts" component={Posts} />
    <PrivateRoute path="/meu-perfil" component={MyProfile} />

    <AdminRoute path="/turmas" component={Classes} />
    <AdminRoute path="/usuarios" component={Users} />
    <AdminRoute path="/intensivao" component={Intensive} />
    <AdminRoute path="/indicacao-premiada" component={Recommendations} />
    <AdminRoute path="/reservas" component={Reservations} />
    <AdminRoute path="/ebook" component={Ebook} />
    <AdminRoute path="/controle-financeiro" component={FinancialControl} />
    <AdminCorrectorRoute path="/horario" component={Schedule} />

    <AdminCorrectorRoute path="/respostas/post/:id" component={Answers} />
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={({ location, ...props }) =>
      localStorage.getItem("token") ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const AdminRoute = ({ component: Component, ...rest }) => {
  const decoded = decodeJwt();

  return (
    <Route
      {...rest}
      render={({ location, ...props }) =>
        localStorage.getItem("token") && decoded.id_group === 1 ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const AdminCorrectorRoute = ({ component: Component, ...rest }) => {
  const decoded = decodeJwt();
  return (
    <Route
      {...rest}
      render={({ location, ...props }) =>
        localStorage.getItem("token") &&
        (decoded.id_group === 2 || decoded.id_group === 1) ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const decodeJwt = () => {
  let decoded;
  try {
    decoded = jwt_decode(localStorage.getItem("token"));
  } catch (error) {
    decoded = {};
  }

  return decoded;
};

export default Routes;
