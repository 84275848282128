import React, { useCallback, useEffect, useState } from "react";
import Title from "../../components/Title";
import { ContainerSchedule, Day, Time } from "./style";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PersonIcon from "@material-ui/icons/Person";
import {
  AppBar,
  Box,
  Chip,
  Dialog,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TodayIcon from "@material-ui/icons/Today";
import API from "../../utils/api";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "../../store/Loading/actions";
import { useDispatch } from "react-redux";
import ButtonAdd from "../../components/ButtonAdd";
import FormSchedule from "../../components/FormSchedule";
import DeleteScheduleModal from "../../components/DeleteScheduleModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ border: "1px solid #e6e6e6" }} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Schedule() {
  const days = [
    {
      key: "monday",
      description: "Segunda-feira",
    },
    {
      key: "tuesday",
      description: "Terça-feira",
    },
    {
      key: "wednesday",
      description: "Quarta-feira",
    },
    {
      key: "thursday",
      description: "Quinta-feira",
    },
    {
      key: "friday",
      description: "Sexta-feira",
    },
  ];

  const dispatch = useDispatch();

  const [schedule, setSchedule] = useState({});

  const [showForm, setShowForm] = useState({ status: false });

  const [deleteScheduleModal, setDeleteScheduleModal] = useState({
    status: false,
  });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const getSchedule = useCallback(async () => {
    dispatch(showLoading());
    try {
      const res = await API.get("horario/listar");

      setSchedule(res?.data ? res.data : {});
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  }, [dispatch]);

  useEffect(() => {
    getSchedule();
  }, [getSchedule]);

  const addSchedule = () => {
    setShowForm({
      status: true,
    });
  };

  const editSchedule = (schedule) => {
    setShowForm({
      status: true,
      data: schedule,
    });
  };

  const closeForm = () => {
    setShowForm({ status: false });
    getSchedule();
  };

  const openModalDeleteSchedule = (schedule) => {
    setDeleteScheduleModal({ status: true, idItem: schedule.ID });
  };

  const closeDeleteScheduleModal = (close) => {
    if (close) {
      deleteSchedule(deleteScheduleModal.idItem);
    } else {
      setDeleteScheduleModal({ status: false, idItem: null });
    }
  };

  const deleteSchedule = async (id) => {
    dispatch(showLoading());

    try {
      const res = await API.delete(`/horario/${id}`);

      toast.success(`${res.data.message}`);

      setDeleteScheduleModal({ status: false, idItem: null });
      getSchedule();
    } catch (error) {
      dispatch(hideLoading());
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  return (
    <>
      <Title name="Horário" />

      <ContainerSchedule>
        {showForm?.status && (
          <FormSchedule closeForm={closeForm} schedule={showForm.data} />
        )}

        {deleteScheduleModal && deleteScheduleModal.status && (
          <Dialog open={deleteScheduleModal.status}>
            <DeleteScheduleModal
              closeModal={(close) => closeDeleteScheduleModal(close)}
            />
          </Dialog>
        )}

        {!showForm?.status && (
          <>
            <p>
              🚀 <strong>Aulas individuais</strong>
            </p>

            <Chip
              color="primary"
              label={`Vagas preenchidas: ${
                schedule?.individualClassCount ?? 0
              }`}
            />
            <Chip
              color="secondary"
              label={`Vagas em aberto: ${
                schedule?.individualClassVacantCount ?? 0
              }`}
            />

            <p>
              🚀 <strong>Mentorias</strong>
            </p>

            <Chip
              color="primary"
              label={`Vagas preenchidas: ${schedule?.mentoringCount ?? 0}`}
            />
            <Chip
              color="secondary"
              label={`Vagas em aberto: ${schedule?.mentoringVacantCount ?? 0}`}
            />

            <p>
              🚀 <strong>Turmas noturnas</strong>
            </p>

            <Chip
              color="primary"
              label={`Quantidade de turmas: ${schedule?.nightClassCount ?? 0}`}
            />

            <div className="wrapper-btn-add">
              <ButtonAdd text="ADICIONAR NOVO HORÁRIO" click={addSchedule} />
            </div>

            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Lays" {...a11yProps(0)} />
                <Tab label="Anabel" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              {days.map((day) => {
                return (
                  <Day key={day.key}>
                    <p>
                      <TodayIcon />
                      <strong>{day.description}:</strong>
                    </p>

                    {schedule?.[day.key]
                      ?.filter((val) => val.OWNER === "Lays")
                      .map((item) => (
                        <Time key={item.ID}>
                          <div className="actions">
                            <Tooltip title="Editar horário">
                              <IconButton
                                color="primary"
                                component="span"
                                onClick={() => editSchedule(item)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Deletar horário">
                              <IconButton
                                color="primary"
                                component="span"
                                onClick={() => openModalDeleteSchedule(item)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>

                          <span>
                            <ScheduleIcon />
                            {item.DESCRIPTION_TIME}
                          </span>
                          <span>
                            <PersonIcon />
                            {item.DESCRIPTION_STUDENT.toLowerCase().indexOf(
                              "vago"
                            ) >= 0 ? (
                              <strong>{item.DESCRIPTION_STUDENT}</strong>
                            ) : (
                              <>{item.DESCRIPTION_STUDENT}</>
                            )}
                          </span>
                        </Time>
                      ))}

                    {(!schedule?.[day.key]?.filter(
                      (val) => val.OWNER === "Lays"
                    ) ||
                      schedule?.[day.key]?.filter((val) => val.OWNER === "Lays")
                        .length === 0) && (
                      <>
                        <span>Sem horário definido</span>
                      </>
                    )}
                  </Day>
                );
              })}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {days.map((day) => {
                return (
                  <Day key={day.key}>
                    <p>
                      <TodayIcon />
                      <strong>{day.description}:</strong>
                    </p>

                    {schedule?.[day.key]
                      ?.filter((val) => val.OWNER === "Anabel")
                      .map((item) => (
                        <Time key={item.ID}>
                          <div className="actions">
                            <Tooltip title="Editar horário">
                              <IconButton
                                color="primary"
                                component="span"
                                onClick={() => editSchedule(item)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Deletar horário">
                              <IconButton
                                color="primary"
                                component="span"
                                onClick={() => openModalDeleteSchedule(item)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>

                          <span>
                            <ScheduleIcon />
                            {item.DESCRIPTION_TIME}
                          </span>
                          <span>
                            <PersonIcon />
                            {item.DESCRIPTION_STUDENT.toLowerCase().indexOf(
                              "vago"
                            ) >= 0 ? (
                              <strong>{item.DESCRIPTION_STUDENT}</strong>
                            ) : (
                              <>{item.DESCRIPTION_STUDENT}</>
                            )}
                          </span>
                        </Time>
                      ))}

                    {(!schedule?.[day.key]?.filter(
                      (val) => val.OWNER === "Anabel"
                    ) ||
                      schedule?.[day.key]?.filter(
                        (val) => val.OWNER === "Anabel"
                      ).length === 0) && (
                      <>
                        <span>Sem horário definido</span>
                      </>
                    )}
                  </Day>
                );
              })}
            </TabPanel>
          </>
        )}
      </ContainerSchedule>
    </>
  );
}
