import styled from "styled-components";

export const ContainerSchedule = styled.div`
  .wrapper-btn-add {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .MuiTabs-indicator {
    background-color: #fadd21 !important;
  }

  .MuiChip-root {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;
export const Day = styled.div`
  overflow: auto;

  :not(:first-child) {
    margin-top: 2.5rem;
  }

  svg {
    margin-right: 5px;
    color: #ffc107;
  }

  p {
    display: flex;
    align-items: center;
  }
`;

export const Time = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    min-width: 142px;

    &:last-child {
      min-width: 260px;
    }
  }

  svg {
    margin-right: 5px;
    color: #2f3a8f;
  }

  .actions {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    svg {
      margin-right: 0;
    }

    span {
      margin-right: 0 !important;
      min-width: initial !important;
    }

    .MuiButtonBase-root {
      padding: 5px;
    }
  }
`;
