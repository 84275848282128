import styled from "styled-components";

export const WrapperBox = styled.div`
  padding: 1rem;
  background: white;
  border-radius: 5px;

  .grade-skill {
    display: flex;
    justify-content: space-between;

    &.skill-1 {
      margin-bottom: 0.8rem;
      .skill-number span {
        background: #6e2167;
        color: white;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .skill-result.active {
        background: #6e2167;
        border-radius: 5px;
        color: white;
        min-width: 28px;
      }
    }

    &.skill-2 {
      margin-bottom: 0.8rem;
      .skill-number span {
        background: #c02a63;
        color: white;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .skill-result.active {
        border-radius: 5px;
        background: #c02a63;
        color: white;
        min-width: 28px;
      }
    }

    &.skill-3 {
      margin-bottom: 0.8rem;
      .skill-number span {
        background: #01579c;
        color: white;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .skill-result.active {
        border-radius: 5px;
        background: #01579c;
        color: white;
        min-width: 28px;
      }
    }

    &.skill-4 {
      margin-bottom: 0.8rem;
      .skill-number span {
        background: #e63405;
        color: white;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .skill-result.active {
        border-radius: 5px;
        background: #e63405;
        color: white;
        min-width: 28px;
      }
    }

    &.skill-5 {
      .skill-number span {
        background: #277663;
        color: white;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .skill-result.active {
        border-radius: 5px;
        background: #277663;
        color: white;
        min-width: 28px;
      }
    }

    .skill-number {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 19px;
        font-weight: bold;
        text-align: center;
        margin-right: 5px;
      }
    }
    .skill-result {
      padding: 5px;
      cursor: pointer;
      min-width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &:not(:last-child) {
        margin-right: 5px;
      }

      span {
        text-align: center;
        font-size: 12px;
      }
      i {
        color: #aaa;
        font-style: normal;
        font-size: 10px;
      }
    }
  }
`;
